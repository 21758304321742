import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Tokyo1 = () => (
  <Layout>
    <GatsbySeo
      title="Universidad de Tokyo"
      titleTemplate="%s | Eduardo Ceballos"
      description=""
      canonical="https://eduardoceballos.com/tokyo"
      openGraph={{
        url: `https://eduardoceballos.com/tokyo`,
        title: `Universidad de Tokyo`,
        description: ``,
        images: [
          {
            url: `https://eduardoceballos.com/seo/tokyo.png`,
            alt: ``,
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>1. Entrevista</h1>

    <h6>
      <a href="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/index.html">
        Fuente original.
      </a>
    </h6>
    <div className="tokyo-menu-flex">
      <Link to="/tokyo">0. Presentación</Link>
      <Link to="/tokyo/1">1. Entrevista</Link>
      <Link to="/tokyo/2">2. Recitación</Link>
      <Link to="/tokyo/3">3. Equipo de producción</Link>
      <Link to="/tokyo/4">4. Agradecimientos</Link>
    </div>

    <h2>Entrevista 1</h2>
    <center>
      <img
        src="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/bmp/amorvida03.bmp"
        alt=""
      />
      <br />
      <iframe
        className="lazyload"
        title="Entrevista - 1"
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/C9_F4-oPvaM"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <br />
      <p>
        VIVIANA: Estamos aquí con el poeta Eduardo Ceballos quien es autor del
        monólogo "Por Amor a la Vida" y bueno, justamente quisiéramos
        preguntarle a él qué es lo que lo inspiró a hacer este trabajo. EDUARDO:
        Bueno, la inspiración de esta obra ha sido un poco la preocupación que
        me crece de ver la degradación del planeta tierra, de ver cómo se
        complica la existencia humana sobre la faz de nuestro planeta, por un
        lado. Y por otro lado ver que los gobiernos no se preocupan en buscar
        una solución para un tema tan puntual.
      </p>
      <p>© Departamento de Español, Universidad de Tokio</p>
    </center>
    <h2>Entrevista 2</h2>
    <center>
      <img
        src="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/bmp/amorvida06.bmp"
        alt=""
      />
      <br />
      <iframe
        className="lazyload"
        title="Entrevista - 2"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/JgH9Cz8ctpg"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <br />
      <p>
        VIVIANA: ¿Y cuáles son los ingredientes que ha ido sumando en esta obra?
        Veo que hay un trabajo emotivo y científico, pero me gustaría que Usted
        detallase más profundamente. EDUARDO: Bueno, acá hay una suma de cosas
        que me dio la historia cultural de los hombres que me han precedido en
        la existencia, con el aporte que hizo cada uno en distintos momentos de
        la historia de la humanidad, desde la ciencia como decías, desde la
        idea, la filosofía y la observación personal de todo este mundo bello
        que me circunda.
      </p>
      <p>© Departamento de Español, Universidad de Tokio</p>
    </center>
    <h2>Entrevista 3</h2>
    <center>
      <iframe
        className="lazyload"
        title="Entrevista - 3"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/BP2BBRiv5vo"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p>
        VIVIANA: Y para poder escribir de este modo, de un modo tan ameno, tan
        didáctico, tan emotivo -porque llega realmente al corazón de cada uno de
        quien lo lee- Eduardo Ceballos tuvo que tener una formación previa. Este
        no es un trabajo que se hace de un momento para otro, sino que se viene
        gestando a lo largo de toda una vida. ¿Qué es de su vida Eduardo
        Ceballos? ¿Qué es de su trayectoria literaria? EDUARDO: Bueno, yo
        comencé desde muy joven, en la adolescencia, a presentar mis escritos
        que se publicaban en los medios, primero de mi provincia, luego a nivel
        nacional, donde incluyo el paisaje exterior e interior. El exterior
        cantando lo que me producen las geografías y el interior el conocimiento
        que uno va adquiriendo y que luego lo personaliza para transferirlo como
        un sueño propio.
      </p>
      <p>© Departamento de Español, Universidad de Tokio</p>
    </center>
    <h2>Entrevista 4</h2>
    <iframe
      className="lazyload"
      title="Entrevista - 4"
      width="560"
      height="315"
      src="https://www.youtube.com/embed/WWA7YKJicyM"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <p>
      VIVIANA: Pero muy lindo. Y mucho más lindo cuando uno sabe que eso va
      acompañado de otras actividades en las que puede transferir este
      conocimiento literario, todo este sentimiento, esta emoción a la gente,
      porque sé que está muy vinculado a los medios de comunicación. EDUARDO:
      Si, andamos haciendo radio hace ya cuarenta y tanto de años, casi medio
      siglo, también aportamos un poco la palabra en los medios televisivos y
      medios escritos... En es una vida dedicada a la cultura, a la idea, y a
      tranferir ese conocimiento que uno ha recogido de otro tipo.
    </p>
    <p>© Departamento de Español, Universidad de Tokio</p>
    <h2>Entrevista 5</h2>
    <center>
      <iframe
        className="lazyload"
        title="Entrevista - 5"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/rQa-By3_Zck"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p>
        Viviana: Y me han dicho por ahí, que usted es algo así como un embajador
        de la cultura de Salta, un representante de nuestra provincia, de
        nuestra ciudad y de los distintos espectáculos que se realizan, ¿es
        verdad? Eduardo: Sí, por suerte también he recorrido muchos países,
        muchos escenarios con grandes artistas por distintos temas culturales.
        Y, bueno, mi vocación es ser útil, sobre todo a la gente joven, a los
        estudiantes, a la gente que anda buscando ideas para encauzar su
        existencia.
      </p>
      <p>© Departamento de Español, Universidad de Tokio</p>
    </center>
    <h2>Entrevista 6</h2>
    <center>
      <iframe
        className="lazyload"
        title="Entrevista - 6"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/6UIAgtqXejk"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p>
        Viviana: Y hablando de la gente que anda buscando ideas, yo quisiera que
        nos deje un mensaje final, una reflexión para la gente que quiera tomar
        como bandera por amor a la vida. Eduardo: Bueno, acá, van a encontrar
        algunos ingredientes que pueden generar inquietudes. Esas inquietudes,
        pueden llevar a cada uno de los oyentes a que descubran el camino para
        hacer un tratado ú otro tipo de aporte, porque la tierra necesita de la
        inteligencia humana de todos los hombres, porque la tierra es nuestra
        casa. Viviana: Muchísimas gracias por su colaboración y su tiempo.
      </p>
      <p>© Departamento de Español, Universidad de Tokio</p>
    </center>
  </Layout>
)

export default Tokyo1
